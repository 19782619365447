<template>
  <div>
    <table-wrapper
      :table-header="headers"
      no-filters
      :fetch-data="fetchData"
      :rows="rows"
      :list-length="userReports.length"
      :pager="pager"
      :page-sizes="pageSizes"
      :data-size="3"
      :change-page-size="changePageSize"
      :is-loading="isLoading"
    >
      <template #body>
        <user-report-history-row
          v-for="userReport in userReports"
          :key="userReport.reportName"
          :user-report="userReport"
          :open-modal-note="openModalNote"
          :reportMe="reportMeExt"
          :small-table="smallTable"
          statuses="report"
        />
      </template>
      <template #noResults>
        <div>
          <loader-info v-if="isLoading" />
          <div v-else>
            <p class="my-3">
              <em>
                <strong>
                  Nessuna segnalazione disponibile
                </strong>
              </em>
            </p>
          </div>
        </div>
      </template>
    </table-wrapper>
    <modal-notes :id="userReportNote" :note="note" />
  </div>
</template>

<script>
import { extractErrorMessage, isNotEmpty, isNotExist } from '@/utils/validators';

const UserReportHistoryRow = () => import('@/components/userReports/UserReportHistoryRow.vue');
const LoaderInfo = () => import('@/components/helpers/LoaderInfo.vue');
const ModalNotes = () => import('@/components/userSections/ModalNotes.vue');
const TableWrapper = () => import('@/components/tableWrapper/TableWrapper.vue');

export default {
  name: 'UserReportHistoryTable',
  components: {
    ModalNotes,
    LoaderInfo,
    UserReportHistoryRow,
    TableWrapper,
  },
  props: {
    completeData: Boolean,
    searchNameExt: String,
    reportMeExt: Boolean,
    entityType: String,
    entityId: String,
    smallTable: Boolean,
    closedOnly: Boolean,
    extraFilters: Array,
  },
  data() {
    return {
      searchBase: {
        search: '',
        page: 0,
        size: 3,
        sort: [{ field: 'lastModified', direction: 'DESC' }],
        filters: [],
        reportMe: this.reportMeExt,
      },
      entityTypeData: this.entityType,
      entityIdData: this.entityId,
      submitted: false,
      note: null,
      customFilters: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters['score/isUserReportsLoading'](this.searchNameExt);
    },
    pager() {
      return this.$store.getters['score/userReportsPageable'](this.searchNameExt);
    },
    rows() {
      return this.pager.totalElements;
    },
    userReports() {
      return this.$store.getters['score/userReportsList'](this.searchNameExt);
    },
    userReportNote() {
      return `userReportNote_${this.searchNameExt}`;
    },
    subjectHeader() {
      if (this.reportMeExt) return 'Segnalatore';
      return 'Soggetto Valutato';
    },
    pageSizes() {
      return [
        { value: 3, text: '3' },
        { value: 5, text: '5' },
        { value: 10, text: '10' },
      ];
    },
    headers() {
      if (this.smallTable) {
        return [
          {
            classes: 'table-cell--compact white-space-nowrap',
            content: 'Stato / Codice',
          },
          {
            classes: 'white-space-nowrap',
            content: 'Date',
          },
          {
            classes: 'w-100',
            content: 'Segnalazione',
          },
        ];
      }
      return [
        {
          classes: 'table-cell--compact white-space-nowrap',
          content: 'Stato / Codice',
        },
        {
          classes: 'white-space-nowrap',
          content: 'Date',
        },
        {
          classes: 'white-space-nowrap',
          content: this.subjectHeader,
        },
        {
          classes: 'w-100',
          content: 'Segnalazione',
        },
        {
          classes: 'table-cell--compact',
          content: '',
        },
      ];
    },
  },
  watch: {
    completeData(val, oldVal) {
      // console.log('watch completeData', val, oldVal);
      if (val !== oldVal && val) this.fetchData(true);
    },
  },
  mounted() {
    if (this.closedOnly) {
      this.searchBase.filters.push(
        {
          field: 'status',
          operation: 'EQ',
          value: 'CONFIRMED',
        },
      );
    }
    // console.log('list created');
    if (isNotEmpty(this.extraFilters)) {
      this.extraFilters.forEach((filter) => this.searchBase.filters.push(filter));
    }
    this.fetchData(true);
  },
  methods: {
    fetchData(reload, nextPage) {
      // console.log('list fectad', this.searchBase, nextPage);
      if (!this.entityIdData && !this.entityTypeData) {
        const currentProfile = this.$store.getters['subject/currentCompany'];
        if (isNotEmpty(currentProfile)) {
          this.entityIdData = currentProfile.companyId;
          this.entityTypeData = 'company';
        } else {
          this.entityTypeData = 'person';
          this.entityIdData = this.$store.getters['subject/currentPerson']?.personId;
        }
      }

      if (isNotExist(this.entityIdData)) return;

      const search = { ...this.searchBase };

      // console.log('fetch', nextPage);
      if (isNotEmpty(nextPage)) {
        const paging = this.$store.getters['score/userReportsPageable'](this.searchNameExt);
        if (nextPage > 0 && nextPage <= paging.totalPages) {
          search.page = nextPage - 1;
        }
      }
      this.$store.dispatch('score/searchUserReports', {
        entityId: this.entityIdData,
        entityType: this.entityTypeData,
        search,
        reset: reload,
        searchName: this.searchNameExt,
      })
        .then(() => {
          this.submitted = false;
          this.searchBase.page = search.page;
        },
        (error) => {
          this.message = extractErrorMessage(error);
          this.submitted = false;
        });
    },
    openModalNote(userReport) {
      // console.log('open note', userReport);
      this.note = userReport.note;
      this.$bvModal.show(this.userReportNote);
    },
    routeTo(path) {
      this.$router.replace(path);
    },
    changePageSize(size) {
      // console.log('change size', size);
      this.searchBase.size = size;
      this.customFilters = true;
      this.fetchData(true);
    },
  },
};
</script>

<style scoped>

</style>
