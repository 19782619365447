import { render, staticRenderFns } from "./UserReportHistoryTable.vue?vue&type=template&id=71d21956&scoped=true&"
import script from "./UserReportHistoryTable.vue?vue&type=script&lang=js&"
export * from "./UserReportHistoryTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71d21956",
  null
  
)

export default component.exports